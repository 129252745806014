<template>
    <div id="app">
      <!-- The router-view component renders the component for the current route -->
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'App',
  });
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  
<template>
  <div>
    <input v-model="password" type="password" placeholder="Enter password" />
    <button @click="checkPassword">Submit</button>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'LandingPage',
  setup() {
    const password = ref('');
    const router = useRouter();

    const store = useStore();

    const checkPassword = () => {
      if (password.value === 'Ver0YH3ct0r!%&%') {
        store.commit('setAuthenticated', true);
        console.log('state:', store.state.isAuthenticated);
        router.push('/home');
      } else {
        alert('Incorrect password');
      }
    };

    return {
      password,
      checkPassword,
    };
  },
};
</script>

import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from './components/HomeComponent.vue';
import CollageComponent from './components/CollageComponent.vue';
import LandingPage from './components/LandingPage.vue';

const routes = [
  {
    path: '/',
    component: LandingPage
  },
  {
    path: '/home',
    // name: 'HomeComponent',
    component: HomeComponent,
  },
  {
    path: '/collage',
    // name: 'CollageComponent',
    component: CollageComponent,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

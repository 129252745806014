// src/store.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    state: {
        authenticated: false,
    },
    mutations: {
        setAuthenticated(state, authenticated) {
            state.isAuthenticated = authenticated;
        },
    },
  plugins: [createPersistedState({
    // storage: window.sessionStorage, // Use sessionStorage instead of localStorage
    key: 'veroyhector-store', // This key will be used in localStorage
  })]
});

<template>
    <div class="por-mas">
      <h1>Por más...</h1>
        <div class="collage-container">
        <div v-for="photo in photos" :key="photo.filename" class="photo-container">
            <!-- Use dynamic require with webpack's require.context -->
            <img :src="getPhotoUrl(photo.filename)" :alt="photo.message" class="photo" @mouseenter="hoveredPhoto = photo" @mouseleave="hoveredPhoto = null">
            <div class="hover-message" v-if="hoveredPhoto === photo">{{ photo.message }}</div>
            <!-- show message under each photo-->
            <div><strong>{{ photo.message }}</strong></div>
        </div>
        </div>
    </div>

  </template>
  
  <script>
  import { onMounted, ref, nextTick } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'CollageComponent',
    setup() {
      const hoveredPhoto = ref(null);

      const store = useStore();
      const router = useRouter();
      // Use require.context to set up a dynamic require
      const photoContext = require.context('@/assets/fotos/', false, /\.jpg$/);
  
      // Function to get the photo URL using the dynamic require
      const getPhotoUrl = (filename) => {
        return photoContext('./' + filename);
      };
  
      const photos = ref([
        { filename: 'foto_1.jpg', message: 'Bodas juntos' },
        { filename: 'foto_2.jpg', message: 'Y sesiones de VGS' },
        { filename: 'foto_3.jpg', message: 'Y fotos viajando' },
        // { filename: 'foto_4.jpg', message: 'Message for photo 4' },
        { filename: 'foto_5.jpg', message: 'Y fotos candentes' },
        { filename: 'foto_6.jpg', message: 'Y atardeceres juntos' },
        { filename: 'foto_7.jpg', message: 'Y paseos en CP' },
        { filename: 'foto_8.jpg', message: 'Y caminatas con nuestro perrijo' },
        { filename: 'foto_9.jpg', message: 'Y visitas culturales' },
        { filename: 'foto_10.jpg', message: 'Y más bodas' },
        { filename: 'foto_11.jpg', message: 'Y más dates' },
        { filename: 'foto_12.jpg', message: 'Y más retos y logros juntos (siempre tu fan #1)' },
        { filename: 'foto_13.jpg', message: 'Y vuelos juntos' },
        { filename: 'foto_14.jpg', message: 'Y disfraces (aunque no te gusten)' },
        { filename: 'foto_15.jpg', message: 'Y más cumpleaños' },
        { filename: 'foto_16.jpg', message: 'Y maas museos' },
        { filename: 'foto_17.jpg', message: 'Y más selfies' },
        { filename: 'foto_18.jpg', message: 'Y más fiesta' },
        { filename: 'foto_19.jpg', message: 'Y más viajes' },
        { filename: 'foto_20.jpg', message: 'Y más museos' },
        { filename: 'foto_21.jpg', message: 'Y más coladas a antros' },
        { filename: 'foto_22.jpg', message: 'Y aventuras juntos' },
        { filename: 'foto_23.jpg', message: 'Y mucho, mucho más...' },
        // ... add all other photos with their messages
      ]);

      onMounted(async () => {
        console.log('Collage component mounted');
        await nextTick();

        // Then scroll to the top of the page
        window.scrollTo(0, 0);

        // check if it is authenticated in state
        // if not, redirect to landing page
        console.log('user:', store.state);

        if (!store.state.isAuthenticated) {
          router.push('/');
        }
      });
  
      return { hoveredPhoto, photos, getPhotoUrl };
    },
  };
  </script>
  
  
  
  <style>
    .por-mas {
        text-align: center;
        margin-top: 20px;
    }
  .collage-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 20px;
  }
  
  .photo-container {
    position: relative;
    width: 200px; /* or any other size */
    margin: 10px;
  }
  
  .photo {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .photo-container:hover .photo {
    transform: scale(1.1);
  }
  
  .hover-message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }
  
  .photo-container:hover .hover-message {
    opacity: 1;
  }
  </style>
  
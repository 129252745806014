<template>
    <div id="app">
      <div class="content">
        <img :src="picture" alt="Us" class="centered-picture">
        <h2>Will you be my Valentine?</h2>
        <button @click="yesClicked">Yes</button>
        <button :style="noButtonStyle" @mouseenter="moveNoButton">No</button>
      </div>
    </div>
  </template>

  
  <script>
    import { ref, onMounted } from 'vue';
    import usPicture from '../assets/fotos/foto_11.jpg';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
  export default {
    name: 'HomeComponent',
    setup(){

        const store = useStore();
        onMounted(() => {
            if (!store.state.isAuthenticated) {
                router.push('/');
            }
            console.log('Home component mounted');
        });

        const router = useRouter();
        
        const picture = ref(usPicture);
        const noButtonStyle = ref({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        });
        
        function moveNoButton() {
            const x = Math.random() * (window.innerWidth - 100);
            const y = Math.random() * (window.innerHeight - 50);
            noButtonStyle.value = {
            ...noButtonStyle.value,
            top: `${y}px`,
            left: `${x}px`,
            };
        }
        
        function yesClicked() {
            console.log('Yes clicked');
            // alert('Happy Valentine’s Day ❤️');
            router.push("/collage");
        }

        return {picture, noButtonStyle, moveNoButton, yesClicked}
    }
  }

  </script>
  
  

  <style>
  body {
    margin: 0;
    padding: 0;
    background-image: url('@/assets/pink-hearts.jpg'); /* Your tileable hearts background */
    background-repeat: repeat;
  }
  
  #app {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
  }
  
  .centered-picture {
    max-width: 100%;
    max-height: 80vh; /* Adjust this value as needed */
    margin-bottom: 20px;
  }
  
  .content {
    padding: 20px;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    border-radius: 15px; /* Optional: for rounded corners */
  }
  
  button {
    margin: 10px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #ff5f5f;
    color: white;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #ff8080;
  }
  </style>
  

  